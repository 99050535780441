import { RouteConfig } from 'vue-router'
import router from '@/router'

const state = {
  isRoutesInstalled: false,
}

// module name must match feature hint from backend API response
export const MODULE_NAME = 'Settings'

interface InstallOptions {
  routes: boolean
}
const defaultInstallOptions: InstallOptions = {
  routes: true,
}
export function install(
  options: InstallOptions = defaultInstallOptions
): Promise<any> {
  const promises = []

  if (options.routes) {
    promises.push(
      import(
        /* webpackChunkName: 'SettingsModule' */ './routes/settings.routes'
      )
        .then(exports.addRoutes)
        .then(() => (state.isRoutesInstalled = true))
    )
  }

  return Promise.all(promises)
}

export function uninstall() {}

export function addRoutes(localRoutes: { default: Array<RouteConfig> }) {
  for (const route of localRoutes.default) {
    router.addRoute(route)
  }
}
