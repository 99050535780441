<template>
  <tabs-topbar
    :tabs="shownTabs"
    :modules="[]"
    data-id-prefix="settings_topbar_tabs"
  />
</template>

<script>
import TabsTopbar from '@/app/util-modules/ui/tabs-topbar/TabsTopbar'
import { MODULE_NAME } from '../settings.module'

export default {
  name: 'SettingsTopbarTabs',
  components: {
    TabsTopbar,
  },
  computed: {
    isDetailsPages() {
      return this.$route.name.includes(`${MODULE_NAME}.details`)
    },
    tabs() {
      return [
        {
          id: 'settings',
          label: () => this.$t('modules.settings.label'),
          route: { name: `${MODULE_NAME}.index` },
          show: this.$route.name === `${MODULE_NAME}.index`,
        },
      ]
    },
    shownTabs() {
      return this.tabs.filter((tab) => tab.show)
    },
  },
}
</script>
