import { MODULE_NAME } from '../settings.module'
import SettingsTopbarTabs from '../components/SettingsTopbarTabs.vue'

const DefaultLayout = () =>
  import(
    /* webpackChunkName: "DefaultLayout" */
    '@/app/util-modules/ui/layouts/DefaultLayout.vue'
  )

export default [
  {
    path: '/workspaces/:workspaceId/settings',
    name: `${MODULE_NAME}.index`,
    meta: {
      layout: DefaultLayout,
      hiddenElements: ['areas', 'tags'],
    },
    components: {
      'topbar-left': SettingsTopbarTabs,
      default: () => import('@/app/modules/settings/pages/SettingsPage.vue'),
    },
  },
]
