











import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/utils/vendors/i18n'
import { BASE_GETTER_OLD_MODULES_URLS } from '@/store/getters/store.getters.names'
import { PRE_RELEASE_APP_URL, STAGING_APP_URL } from '@/constants'

export default defineComponent({
  name: 'SettingsPage',
  setup() {
    const workspaceId = computed(() => store.state.workspace.workspace.id)
    const locale = ref(i18n.locale)
    const workspaceSettingsUrl = computed(
      () => store.getters[BASE_GETTER_OLD_MODULES_URLS].WorkspaceSettings
    )

    const workspaceSettings = computed(() => {
      const workspaceUri = `companies/${workspaceId.value}/settings?tab=master-data&skip_sidebar=true`

      if (process.env.VUE_APP_API_URL?.includes('pre-release'))
        return `${PRE_RELEASE_APP_URL}/${workspaceUri}`
      else if (process.env.VUE_APP_MODE === 'production')
        return `${workspaceSettingsUrl.value}&skip_sidebar=true`
      else return `${STAGING_APP_URL}/${workspaceUri}`
    })

    watch(
      () => i18n.locale,
      () =>
        setTimeout(() => {
          locale.value = i18n.locale
        }, 500)
    )

    return { workspaceSettings, locale }
  },
})
